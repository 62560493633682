<template>
    <div class="mx-7">
        <v-card
            class="mx-auto my-5 card-info"
            shaped
        >
            <v-card-actions class="my-0">
                <v-row justify="end">
                    <v-btn
                         class="my-3 mx-5"
                        icon
                        @click="hideHelp"
                        v-if="show"
                    >
                    <v-icon>
                    mdi-square-off-outline
                    </v-icon>
                    </v-btn>
                    <v-btn
                         class="my-3 mx-5"
                        icon
                        @click="showHelp"
                        v-else
                    >
                    <v-icon>
                    mdi-square
                    </v-icon>
                    </v-btn>
                </v-row>
            </v-card-actions>
            <v-list-item three-line v-if="show">
            <v-list-item-content>
                <v-list-item-title class="headline mb-1 text-center">
                ¿Qué tenemos aquí?
                </v-list-item-title>
                <v-card-text>{{ message }}</v-card-text>
            </v-list-item-content>
            </v-list-item>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'LayoutMoreInfo',
    data() {
        return {
            show: true
        }
    },
    mounted() {
        if (localStorage.getItem('helpNotShow')) {
            const helpArray = localStorage.getItem('helpNotShow').split(',');
            if (helpArray.includes(this.$route.name)) {
                this.show = false;
            }
        }
    },
    methods: {
        hideHelp() {
            let helpArray;
            if (!localStorage.getItem('helpNotShow')) {
                helpArray = [];
            } else {
                helpArray = localStorage.getItem('helpNotShow').split(',');
            }
            if (!helpArray.includes(this.$route.name)) {
                helpArray.push(this.$route.name);
                localStorage.setItem('helpNotShow', helpArray.join());
            }
            this.show = false;
        },
        showHelp() {
            const helpArray = localStorage.getItem('helpNotShow').split(',');
            const index = helpArray.indexOf(this.$route.name);
            if (index > -1) {
                helpArray.splice(index, 1);
                localStorage.setItem('helpNotShow', helpArray.join());
            }
            this.show = true;
        }
    },
    computed: {
        message: function() {
            const path = this.$route.name;

            if (path === 'Office') {
                return 'Hola, en esta sección podrá crear, editar y eliminar las oficinas de su empresa, no hay límite de oficinas que pueda cargar. Pra crear una nueva oficina puede seleccionar la última tarjeta con el signo más "+" e inmediatamente se abrirá un formulario para que ingrese los datos necesarios, luego de click en GUARDAR y listo, su nueva oficina estará lista para agregar los usuarios que operan en ella. Las tarjetas que ve abajo muestran un breve resumen de cada oficina como: ciudad, departamento, nombre, descripción y un conteo de los usuarios (personas a cargo de su oficina), aportantes (empresas que pagan la seguridad social) y afiliados (empleados de los aportantes). Para editar o eliminar una oficina es tan fácil como dar click sobre la tarjeta correspondiente y realizar la acción que desee.';
            } else if (path === 'User') {
                return 'Hola de nuevo, esta parte del aplicativo tiene la función de administrar los usuarios (personas a cargo de sus oficinas), aquí puede crear, editar y eliminar los roles de usuario de sus empleados, no hay límite de usuarios que puede crear. A continuación verá una lista de tarjetas que corresponden a las oficinas que haya creado, para crear un nuevo usuario, primero debe saber a que oficina lo asignará, una vez hecho esto visualice el signo más "+" en la tarjeta correspondiente a la oficina e inmediatamente se abrirá un formulario para que ingrese los datos necesarios, luego de cliuck en GUARDAR y listo, su usuario será asignado a una oficina y podrá iniciar sesión en el aplicativo. Para editar o eliminar un usuario es tan fácil como dar click sobre la imagen correspondiente y realizar la acción que desee. Los usuarios podrán administrar los aportantes y afiliados que estén registrados en su empresa.';
            } else if (path === 'Contributor Main') {
                return 'Los Aportantes son personas naturales o jurídicas que pagan la seguridad social en su empresa; dando click en el botón Nuevo Aportante podrá visualizar un formulario para diligenciar la información necesaria para crearlo. En la parte inferior se encuentra una tabla con los Aportantes creados, podrá utilizar el buscador que está junto al botón de Nuevo Aportante si desea filtrar las filas. Cada fila, al final, tiene tres puntos que corresponden a las acciones de Editar, Transferir o Eliminar al Aportante correspondiente. Con la opción Editar se abrirá nuevamente el formulario de creación de Aportante, pero esta vez con la información ya cargadsa de este mismo, cambiando los campos y dando click en ACTUALIZAR se cambiará los del Aportante, Con Transferir podrá mover los del Aportante y los Afiliados de este a otra Oficina de su empresa. Finalmente, con Eliminar se borrará el Aportante, pero los Afiliados de este seguirán vigentes en el software.';
            } else if (path === 'Branch Main') {
                return 'Las Sucursales...';
            } else if (path === 'Affiliate Main') {
                return '';
            }
            return '';
        }
    }
}
</script>
