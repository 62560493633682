<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            No es posible realizar esta acción
          </v-card-title>
          <v-card-text>Para continuar es necesario tener al menos una oficina, para hacerlo haga click en el siguiente botón.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="$router.push({ name: 'Office'})"
            >
              Crear Oficina
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row no-gutters class="mx-1">
      <v-col
        v-for="office in offices"
        :key="office._id"
        cols="12"
        lg="4"
        xl="3"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              class="mx-4 mb-7"
              style="cursor: pointer;"
              @click="goOffice(office._id)"
            >
              <v-img height="200" v-bind:src="office.bannerRoute"></v-img>

              <v-card-text style="height: 200px">
                <div  class="text-capitalize">{{ office.cityName }}, {{ office.stateName }}</div>
                <h2 class="title primary--text">
                  {{ office.name }}
                </h2>
                {{ office.description }}
              </v-card-text>

              <v-card-title>
                <v-row no-gutters>
                  <v-col cols="12" lg="4">
                    <h6 class="font-weight-light text-center">
                      <strong>0</strong><br>Usuarios
                    </h6>
                  </v-col>
                  <v-divider
                      vertical
                  ></v-divider>
                  <v-col cols="12" lg="4">
                    <h6 class="font-weight-light text-center">
                      <strong>{{ office.contributorCount }}</strong><br>Aportantes
                    </h6>
                  </v-col>
                  <v-divider
                      vertical
                  ></v-divider>
                  <v-col cols="12" lg="4">
                    <h6 class="font-weight-light text-center">
                      <strong>{{ office.affiliateCount }}</strong><br>Afiliados
                    </h6>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-fade-transition v-if="addOffice">
                <v-overlay
                  style="cursor: default;"
                  v-if="hover"
                  absolute
                  color="#036358"
                >
                  <div class="text-center my-3">
                      <v-btn @click="editOffice(office._id)">Editar</v-btn>
                  </div>
                  <div class="text-center my-3">
                      <v-btn @click="deleteOffice(office._id)">Eliminar</v-btn>
                  </div>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col
        v-if="addOffice"
        @click="newOffice"
        cols="12"
        lg="4"
        xl="3"
      >
        <v-card class="mx-4 mb-7 d-flex justify-center align-center" style="height: calc(100% - 28px); min-height: 371px; cursor: pointer">
          <v-icon style="font-size: 150px; height: fit-content;" color="primary">mdi-plus-circle-outline</v-icon>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
    name: 'LayoutOfficeList',
    data() {
        return {
          dialog: false,
          overlay: false,
          offices: []
        }
    },
    props: {
      addOffice: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      editOffice(_id) {
            this.$router.push({ name: "Office Edit", params: {
                object_id: _id
            }}); 
        },
      newOffice() {
        this.$router.push({ name: "Office Create" });   
      },
      goOffice(_id) {
        if (!this.addOffice) {
          const Component = this.$route.name +' Main';
          this.$router.push({ name: Component, params: { _id: _id } });
        }
      },
      deleteOffice(_id) {
        Swal.fire({
          title: "¿Eliminar Oficina?",
          showCancelButton: true,
          confirmButtonText: `Eliminar`,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d"
        }).then(async result => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: "warning",
              title: "Eliminando Oficina",
              html:
                "Por favor espere mientras se elimina la Oficina</b> " +
                '<progress id="progressBar" value="0" max="100"></progress>',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              stopKeydownPropagation: true
            });
            try {
              await store.deleteData("misalud", "office", _id);
              Swal.fire({
                icon: "success",
                title: "Oficina eliminada",
                text: "Todos los datos han sido sincronizados"
              }).then(() => {
                window.location.href = "/loading";
              });
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Error al eliminar la Oficina"
              }) 
            }
          }
        });
      }
    },
    async mounted() {
      const data = await store.getData("office");
      const contributors = await store.getData("contributor");
      const branches = await store.getData("branch");
      const affiliates = await store.getData("affiliate");
      if (!this.addOffice && data.length === 0) {
        this.dialog = true;
      }
      else if (!this.addOffice && data.length === 1) {
        const Component = this.$route.name +' Main';
        const _id = data[0]._id;
        this.$router.push({ name: Component, params: { _id: _id } })
      }
      this.offices = [];
      data.forEach(async office => {
        const state = await store.getData("state", office.state);
        office.stateName = state.name;

        const city = await store.getData("city", office.city);
        office.cityName = city.name;

        office.contributorCount = 0;
        office.affiliateCount = 0;
        contributors.forEach(contributor => {
          if (contributor.office === office._id) {
            office.contributorCount ++;
            branches.forEach(branch => {
              if (branch.contributor === contributor._id) {
                affiliates.forEach(affiliate => {
                  if (affiliate.branch === branch._id) {
                    office.affiliateCount ++;
                  }
                })
              }
            });
          }
        });

        if (office.bannerRoute == null) {
          office.bannerRoute = store.staticURL + "/" + "img/default/office.jpg";
        }
        this.offices.push(office);
      });
    },
}
</script>