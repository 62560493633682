







import MoreInfo from '@/components/Layout/MoreInfo.vue'
import OfficeList from '@/components/Layout/OfficeList.vue'
export default {
    name: 'OfficeMain',
    components: {
        MoreInfo,
        OfficeList
    }
}
